import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { AddUserSchema } from "../../../Validations/AddUserValidations";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import { registerUser } from "../../../services/authService";
import { getAllLocationCode } from "../../../services/LocationService";
import { getDefaultRoles, getRoles } from "../../../services/roleService";

import countryService from "../../../services/countryService";

function AddDefaultUser() {
  const [user, setUser] = useState({
    firstname: "",
    middlename: "",
    lastname: "",
    email: "",
    password: "",
    actual_password: "",
    userlevel: "",
    location: "",
    address1: "",
    address2: "",
    zippostal: "",
    country: "",
    state: "",
    city: "",
    landlinenumber: "",
    mobileNumber: "",

    gender: "",
    dob: "",
    birthCountry: "",
    birthState: "",
    birthCity: "",
    nationality: "",
    riskRating: "",
    idType: "",
    idNumber: "",
    idIssueDate: "",
    idExpiryDate: "",
    idIssuingAuthority: "",
    idIssuingCountry: "",
    role_id: "",
  });
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [locationTo, setLocationTo] = useState("");
  const [roles, setRoles] = useState([]);
  const [countries, setCountries] = useState([]);

  const [birthCountry, setBirthCountry] = useState([]);
  const [nationality, setNationality] = useState([]);
  const [issuingCountry, setIssuingCountry] = useState([]);

  const [address1, setAddress1] = useState("");
  const [address2, setAddress2] = useState("");

  const [birthStateOptions, setBirthStateOptions] = useState([]);
  let birthState_options = [];

  const [birthCityOptions, setBirthCityOptions] = useState([]);
  let birthCity_options = [];

  const [stateOptions, setStateOptions] = useState([]);
  var state_options = [];

  const [cityOptions, setCityOptions] = useState([]);
  var city_options = [];

  const country_options = [];
  const birthCountry_options = [];
  const nationality_options = [];
  const issuingCountry_options = [];

  country_options.push({
    value: "All",
    label: "All",
  });

  countries?.map((country, index) => {
    return country_options.push({
      value: country.name,
      label: country.name,
      id: country.id,
    });
  });

  birthCountry?.map((country, index) => {
    return birthCountry_options.push({
      value: country.name,
      label: country.name,
      id: country.id,
    });
  });

  nationality?.map((nationality, index) => {
    return nationality_options.push({
      value: nationality.name,
      label: nationality.name,
      id: nationality.id,
    });
  });

  issuingCountry?.map((isCountry, index) => {
    return issuingCountry_options.push({
      value: isCountry.name,
      label: isCountry.name,
      id: isCountry.id,
    });
  });

  useEffect(async () => {
    const data = await countryService.getCountries();
    setCountries(data);
    setBirthCountry(data);
    setNationality(data);
    setIssuingCountry(data);
  }, []);

  useEffect(async () => {
    getAllLocationCode(dispatch);
    try {
      const allRoles = await getDefaultRoles();
      setRoles(allRoles.roles);
    } catch (error) {}
  }, []);

  const handleChange = (e) => {
    setUser({ ...user, [e.target.name]: e.target.value });
  };

  const handleDateChange = (e) => {
    var ageInMilliseconds = new Date() - new Date(e.target.value);
    var age = Math.floor(ageInMilliseconds / 1000 / 60 / 60 / 24 / 365); // convert to years

    if (age < 18) {
      alert("Age must be more than 18 Years");
    } else {
      setUser({ ...user, [e.target.name]: e.target.value });
    }
  };

  const location_options = [];
  const role_options = [];

  const { result } = useSelector((state) => state.location.locations);
  result?.map((location, index) => {
    return location_options.push({
      value: location.location_code,
      label: location.location_code,
      currency: location.currency,
      address1: location.address_1,
      address2: location.address_2,
    });
  });

  roles?.map((role, index) => {
    return role_options.push({
      value: role.role_name,
      label: role.role_name,
      role_id: role.id,
    });
  });

  const handleChangeLocation = (selected) => {
    setLocationTo(selected.value);

    location_options.forEach(function (arrayItem) {
      var x = arrayItem.value;

      if (x == selected.value) {
        setAddress1(arrayItem.address1);
        setAddress2(arrayItem.address2);
        setUser({
          ...user,
          address1: arrayItem.address1,
          address2: arrayItem.address2,
        });
      }
    });
  };

  const handleRoleChange = (selectedOption) => {
    setUser((prev) => ({
      ...prev,
      role_id: selectedOption.role_id,
      userlevel: selectedOption.value,
    }));
  };
  useEffect(() => {
    setUser((prev) => ({
      ...prev,
    }));
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const isValid = await AddUserSchema.isValid(user);

    if (!isValid) {
      toast.error("Please enter all the required fields!!");
    } else {
      try {
        const form = {
          ...user,
          location: locationTo,
        };
        setLoading(true);
        registerUser(form);
        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    }
  };

  const handleChangeCountry = async (selectedOption) => {
    setUser({ ...user, country: selectedOption.value });

    const data = await countryService.getCountryStates(selectedOption.id);

    data?.map((state, index) => {
      return state_options.push({
        value: state.name,
        label: state.name,
        id: state.id,
      });
    });

    setStateOptions(state_options);
  };

  const handleChangeState = async (selectedOption) => {
    setUser({ ...user, state: selectedOption.value });

    const data = await countryService.getCountryStateCities(selectedOption.id);
    data?.map((city, index) => {
      return city_options.push({
        value: city.name,
        label: city.name,
        id: city.id,
      });
    });

    setCityOptions(city_options);
  };

  const handleChangeCity = async (selectedOption) => {
    setUser({ ...user, city: selectedOption.value });
  };

  const handleChangeBirthCountry = async (selectedOption) => {
    setUser({ ...user, birthCountry: selectedOption.value });

    const data = await countryService.getCountryStates(selectedOption.id);

    data?.map((state, index) => {
      return birthState_options.push({
        value: state.name,
        label: state.name,
        id: state.id,
      });
    });

    setBirthStateOptions(birthState_options);
  };

  const handleChangeBirthState = async (selectedOptions) => {
    setUser({ ...user, birthState: selectedOptions.value });
    const data = await countryService.getCountryStateCities(selectedOptions.id);

    data?.map((birthCity, index) => {
      return birthCity_options.push({
        value: birthCity.name,
        label: birthCity.name,
        id: birthCity.id,
      });
    });

    setBirthCityOptions(birthCity_options);
  };

  const handleChangeBirthCity = async (selectedOption) => {
    setUser({ ...user, birthCity: selectedOption.value });
  };

  const handleChangeNationality = (selectedOption) => {
    setUser({ ...user, nationality: selectedOption.value });
  };

  const handleChangeIssuingCountry = (selectedOption) => {
    setUser({ ...user, issuingCountry: selectedOption.value });
  };

  return (
    <div className="app-card app-card-settings shadow-sm p-4">
      <div className="app-card-body">
        <form onSubmit={handleSubmit}>
          <div className="row mb-3 g-3">
            <div className="col-md-12 border p-3 rounded">
              <div className="form-group row mb-3">
                <label
                  for="firstname"
                  className="col-form-label col-md-4"
                  style={{
                    fontSize: "13px",
                    fontWeight: "600",
                  }}
                >
                  First Name<sup className="text-danger bold">*</sup>
                </label>
                <div className="col-md-8">
                  <input
                    style={{ fontSize: "11px" }}
                    type="text"
                    id="firstname"
                    name="firstname"
                    className="form-control"
                    placeholder="First name"
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className="form-group row mb-3">
                <label
                  for="middlename"
                  className="col-form-label col-md-4"
                  style={{
                    fontSize: "13px",
                    fontWeight: "600",
                  }}
                >
                  Middle Name:
                </label>
                <div className="col-md-8">
                  <input
                    style={{ fontSize: "11px" }}
                    type="text"
                    id="middlename"
                    name="middlename"
                    className="form-control"
                    placeholder="Middle name"
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className="form-group row mb-3">
                <label
                  for="lastname"
                  className="col-form-label col-md-4"
                  style={{
                    fontSize: "13px",
                    fontWeight: "600",
                  }}
                >
                  Last Name<sup className="text-danger bold">*</sup>
                </label>
                <div className="col-md-8">
                  <input
                    style={{ fontSize: "11px" }}
                    type="text"
                    id="lastname"
                    name="lastname"
                    className="form-control"
                    placeholder="Last name"
                    onChange={handleChange}
                    // {...formik.getFieldProps("lastname")}
                  />
                  {/* {formik.touched.lastname && formik.errors.lastname ? (
                      <div className="text-danger">
                        {formik.errors.lastname}
                      </div>
                    ) : null} */}
                </div>
              </div>
              <div className="form-group row mb-3">
                <label
                  for="email"
                  className="col-form-label col-md-4"
                  style={{
                    fontSize: "13px",
                    fontWeight: "600",
                  }}
                >
                  Email<sup className="text-danger bold">*</sup>
                </label>
                <div className="col-md-8">
                  <input
                    style={{ fontSize: "11px" }}
                    type="email"
                    id="email"
                    name="email"
                    className="form-control"
                    placeholder="Email"
                    onChange={handleChange}
                    // {...formik.getFieldProps("email")}
                  />
                  {/* {formik.touched.email && formik.errors.email ? (
                      <div className="text-danger">{formik.errors.email}</div>
                    ) : null} */}
                </div>
              </div>

              {/* My work starts here Shyn */}
              <div className="form-group row mb-3">
                <label
                  for="password"
                  className="col-form-label col-md-4"
                  style={{
                    fontSize: "13px",
                    fontWeight: "600",
                  }}
                >
                  Password:
                </label>
                <div className="col-md-8">
                  <input
                    style={{ fontSize: "11px" }}
                    type="password"
                    id="password"
                    name="password"
                    className="form-control"
                    placeholder="password"
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className="form-group row mb-3">
                <label
                  for="password"
                  className="col-form-label col-md-4"
                  style={{
                    fontSize: "13px",
                    fontWeight: "600",
                  }}
                >
                  Actual Password:
                </label>
                <div className="col-md-8">
                  <input
                    style={{ fontSize: "11px" }}
                    type="password"
                    id="actual_password"
                    value={user?.actual_password}
                    name="actual_password"
                    className="form-control"
                    placeholder=""
                    onChange={handleChange}
                    readOnly
                  />
                </div>
              </div>
              <div className="form-group row mb-3">
                <label
                  for="userlevel"
                  className="col-form-label col-md-4"
                  style={{
                    fontSize: "13px",
                    fontWeight: "600",
                  }}
                >
                  User Role:<sup className="text-danger bold">*</sup>
                </label>
                <div
                  className="col-md-8"
                  style={{
                    fontSize: "13px",
                    fontWeight: "600",
                  }}
                >
                  <Select
                    name="userlevel"
                    options={role_options}
                    className="basic-multi-select"
                    classNamePrefix="select"
                    onChange={handleRoleChange}
                  />
                </div>
              </div>

              <div className="form-group row mb-3">
                <label
                  for="country"
                  className="col-form-label col-md-4"
                  style={{
                    fontSize: "13px",
                    fontWeight: "600",
                  }}
                >
                  Country<sup className="text-danger bold">*</sup>
                </label>
                <div className="col-md-8">
                  <Select
                    name="country"
                    options={country_options}
                    className="basic-multi-select"
                    classNamePrefix="select"
                    onChange={handleChangeCountry}
                  />
                </div>
              </div>

              <div className="form-group row mb-3">
                <label
                  for="city"
                  className="col-form-label col-md-4"
                  style={{
                    fontSize: "13px",
                    fontWeight: "600",
                  }}
                >
                  State<sup className="text-danger bold">*</sup>
                </label>
                <div className="col-md-8">
                  <Select
                    name="state"
                    options={stateOptions}
                    className="basic-multi-select"
                    classNamePrefix="select"
                    onChange={handleChangeState}
                  />
                </div>
              </div>

              <div className="form-group row mb-3">
                <label
                  for="city"
                  className="col-form-label col-md-4"
                  style={{
                    fontSize: "13px",
                    fontWeight: "600",
                  }}
                >
                  City<sup className="text-danger bold">*</sup>
                </label>
                <div className="col-md-8">
                  <Select
                    name="city"
                    options={cityOptions}
                    className="basic-multi-select"
                    classNamePrefix="select"
                    onChange={handleChangeCity}
                  />
                </div>
              </div>
              <div className="form-group row mb-3">
                <label
                  for="address1"
                  className="col-form-label col-md-4"
                  style={{
                    fontSize: "13px",
                    fontWeight: "600",
                  }}
                >
                  Location:<sup className="text-danger bold">*</sup>
                </label>
                <div
                  className="col-md-8"
                  style={{
                    fontSize: "13px",
                    fontWeight: "600",
                  }}
                >
                  <Select
                    name="location"
                    options={location_options}
                    className="basic-multi-select"
                    classNamePrefix="select"
                    onChange={handleChangeLocation}
                  />
                </div>
              </div>
              <div className="form-group row mb-3">
                <label
                  for="address1"
                  className="col-form-label col-md-4"
                  style={{
                    fontSize: "13px",
                    fontWeight: "600",
                  }}
                >
                  Address 1<sup className="text-danger bold">*</sup>
                </label>
                <div className="col-md-8">
                  <input
                    style={{ fontSize: "11px" }}
                    type="text"
                    id="address1"
                    name="address1"
                    className="form-control"
                    placeholder="Address 1"
                    value={address1}
                    onChange={handleChange}
                    // {...formik.getFieldProps("address1")}
                  />
                  {/* {formik.touched.address1 && formik.errors.address1 ? (
                      <div className="text-danger">
                        {formik.errors.address1}
                      </div>
                    ) : null} */}
                </div>
              </div>
              <div className="form-group row mb-3">
                <label
                  for="address2"
                  className="col-form-label col-md-4"
                  style={{
                    fontSize: "13px",
                    fontWeight: "600",
                  }}
                >
                  Address 2:
                </label>
                <div className="col-md-8">
                  <input
                    style={{ fontSize: "11px" }}
                    type="text"
                    id="address2"
                    name="address2"
                    className="form-control"
                    placeholder="Address 2"
                    value={address2}
                    onChange={handleChange}
                  />
                </div>
              </div>

              <div className="form-group row mb-3">
                <label
                  for="zippostal"
                  className="col-form-label col-md-4"
                  style={{
                    fontSize: "13px",
                    fontWeight: "600",
                  }}
                >
                  Zip Postal<sup className="text-danger bold">*</sup>
                </label>
                <div className="col-md-8">
                  <input
                    style={{ fontSize: "11px" }}
                    type="text"
                    id="zippostal"
                    name="zippostal"
                    className="form-control"
                    placeholder="Zip Postal"
                    onChange={handleChange}
                    // {...formik.getFieldProps("zippostal")}
                  />
                  {/* {formik.touched.zippostal && formik.errors.zippostal ? (
                      <div className="text-danger">
                        {formik.errors.zippostal}
                      </div>
                    ) : null} */}
                </div>
              </div>
              <div className="form-group row mb-3">
                <label
                  for="landlinenumber"
                  className="col-form-label col-md-4"
                  style={{
                    fontSize: "13px",
                    fontWeight: "600",
                  }}
                >
                  Landline Number:
                </label>
                <div className="col-md-8">
                  <input
                    style={{ fontSize: "11px" }}
                    type="text"
                    id="landlinenumber"
                    name="landlinenumber"
                    className="form-control"
                    placeholder="Landline Number"
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className="form-group row mb-3">
                <label
                  for="mobileNumber"
                  className="col-form-label col-md-4"
                  style={{
                    fontSize: "13px",
                    fontWeight: "600",
                  }}
                >
                  Mobile Number<sup className="text-danger bold">*</sup>
                </label>
                <div className="col-md-8">
                  <input
                    style={{ fontSize: "11px" }}
                    type="tel"
                    id="mobileNumber"
                    name="mobileNumber"
                    className="form-control"
                    placeholder="Mobile Number"
                    onChange={handleChange}
                    // {...formik.getFieldProps("mobileNumber")}
                  />
                  {/* {formik.touched.mobileNumber &&
                    formik.errors.mobileNumber ? (
                      <div className="text-danger">
                        {formik.errors.mobileNumber}
                      </div>
                    ) : null} */}
                </div>
              </div>
            </div>

            <div className="border rounded p-3 mb-3">
              <h6>
                <strong>Personal Info</strong>
              </h6>
              <div className="row">
                <div className="col-md-8">
                  <div className="form-group row mb-3">
                    <label
                      for="gender"
                      className="col-form-label col-md-4"
                      style={{
                        fontSize: "13px",
                        fontWeight: "600",
                      }}
                    >
                      Gender:<sup className="text-danger bold">*</sup>
                    </label>
                    <div className="col-md-8">
                      <select
                        className="form-control form-select"
                        id="gender"
                        name="gender"
                        style={{ fontSize: "11px" }}
                        onChange={handleChange}
                        // {...formik.getFieldProps("gender")}
                      >
                        <option>--- select gender ---</option>
                        <option value="male">Male</option>
                        <option value="female">Female</option>
                      </select>
                      {/* {formik.touched.gender && formik.errors.gender ? (
                            <div className="text-danger text-sm">
                              {formik.errors.gender}
                            </div>
                          ) : null} */}
                    </div>
                  </div>
                  <div className="form-group row mb-3">
                    <label
                      for="dob"
                      className="col-form-label col-md-4"
                      style={{
                        fontSize: "13px",
                        fontWeight: "600",
                      }}
                    >
                      Date of Birth:
                      <sup className="text-danger bold">*</sup>
                    </label>
                    <div className="col-md-8">
                      <input
                        type="date"
                        id="dob"
                        name="dob"
                        className="form-control"
                        style={{ fontSize: "11px" }}
                        onChange={handleDateChange}
                        // {...formik.getFieldProps("dob")}
                      />
                      {/* {formik.touched.dob && formik.errors.dob ? (
                            <div className="text-danger text-sm">
                              {formik.errors.dob}
                            </div>
                          ) : null} */}
                    </div>
                  </div>
                  <div className="form-group row mb-3">
                    <label
                      for="birthCountry"
                      className="col-form-label col-md-4"
                      style={{
                        fontSize: "13px",
                        fontWeight: "600",
                      }}
                    >
                      Birth Country:
                      <sup className="text-danger bold">*</sup>
                    </label>
                    <div className="col-md-8">
                      <Select
                        name="birthCountry"
                        options={birthCountry_options}
                        className="basic-multi-select"
                        classNamePrefix="select"
                        onChange={handleChangeBirthCountry}
                      />
                    </div>
                  </div>
                  <div className="form-group row mb-3">
                    <label
                      for="birthState"
                      className="col-form-label col-md-4"
                      style={{
                        fontSize: "13px",
                        fontWeight: "600",
                      }}
                    >
                      State:<sup className="text-danger bold">*</sup>
                    </label>
                    <div className="col-md-8">
                      {/* <input
                      style={{ fontSize: "11px" }}
                      type="text"
                      id="country"
                      name="country"
                      className="form-control"
                      placeholder="Country"
                      onChange={handleChange}
                    /> */}
                      <Select
                        name="birthState"
                        options={birthStateOptions}
                        className="basic-multi-select"
                        classNamePrefix="select"
                        onChange={handleChangeBirthState}
                      />
                    </div>
                  </div>
                  <div className="form-group row mb-3">
                    <label
                      for="birthCity"
                      className="col-form-label col-md-4"
                      style={{
                        fontSize: "13px",
                        fontWeight: "600",
                      }}
                    >
                      Birth City:
                      <sup className="text-danger bold">*</sup>
                    </label>
                    <div className="col-md-8">
                      <Select
                        name="birthCity"
                        options={birthCityOptions}
                        className="basic-multi-select"
                        classNamePrefix="select"
                        onChange={handleChangeBirthCity}
                      />
                    </div>
                  </div>
                  <div className="form-group row mb-3">
                    <label
                      for="nationality"
                      className="col-form-label col-md-4"
                      style={{
                        fontSize: "13px",
                        fontWeight: "600",
                      }}
                    >
                      Nationality:
                      <sup className="text-danger bold">*</sup>
                    </label>
                    <div className="col-md-8">
                      <Select
                        name="nationality"
                        options={nationality_options}
                        className="basic-multi-select"
                        classNamePrefix="select"
                        onChange={handleChangeNationality}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="position-relative row form-group">
                    <div className="col-sm-12">
                      <div
                        className="border rounded text-center"
                        style={{
                          backgroundColor: "aliceblue",
                          height: "250px",
                          width: "100%",
                        }}
                      >
                        <img id="personal-info-img" width="150" src="" />
                      </div>
                      <input
                        name="profileImage"
                        type="file"
                        className="form-control-file mt-2"
                        style={{
                          fontSize: "13px",
                        }}
                        // onChange={handleChange}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* Identification Information */}
            <div className="border rounded p-3 mb-3">
              <h6 className="mb-3">
                <strong>Identification Information</strong>
              </h6>
              <div className="col-md-12">
                <div className="position-relative row form-group">
                  <div className="form-group row mb-3">
                    <label
                      for="idType"
                      className="col-form-label col-md-4"
                      style={{
                        fontSize: "13px",
                        fontWeight: "600",
                      }}
                    >
                      ID Type:<sup className="text-danger bold">*</sup>
                    </label>
                    <div className="col-md-8">
                      <select
                        name="idType"
                        className="form-control form-control-sm form-select"
                        style={{
                          fontSize: "13px",
                        }}
                        onChange={handleChange}
                        // {...formik.getFieldProps("idType")}
                      >
                        <option>National ID Document</option>
                        <option>Passport</option>
                        <option>Driving License</option>
                      </select>
                      {/* {formik.touched.idType && formik.errors.idType ? (
                            <div>{formik.errors.idType}</div>
                          ) : null} */}
                    </div>
                  </div>
                  <div className="form-group row mb-3">
                    <label
                      for="idNumber"
                      className="col-form-label col-md-4"
                      style={{
                        fontSize: "13px",
                        fontWeight: "600",
                      }}
                    >
                      ID Number:
                      <sup className="text-danger bold">*</sup>
                    </label>
                    <div className="col-md-8">
                      <input
                        style={{ fontSize: "11px" }}
                        type="text"
                        id="idNumber"
                        name="idNumber"
                        className="form-control"
                        placeholder="ID number"
                        onChange={handleChange}
                        // {...formik.getFieldProps("idNumber")}
                      />
                      {/* {formik.touched.idNumber && formik.errors.idNumber ? (
                            <div className="text-danger">
                              {formik.errors.idNumber}
                            </div>
                          ) : null} */}
                    </div>
                  </div>
                  <div className="form-group row mb-3">
                    <label
                      for="isIssueDate"
                      className="col-form-label col-md-4"
                      style={{
                        fontSize: "13px",
                        fontWeight: "600",
                      }}
                    >
                      ID Issue Date:
                      <sup className="text-danger bold">*</sup>
                    </label>
                    <div className="col-md-8">
                      <input
                        style={{ fontSize: "11px" }}
                        type="date"
                        id="idIssueDate"
                        name="idIssueDate"
                        className="form-control"
                        onChange={handleChange}
                        // {...formik.getFieldProps("idIssueDate")}
                      />
                      {/* {formik.touched.idIssueDate &&
                          formik.errors.idIssueDate ? (
                            <div className="text-danger">
                              {formik.errors.idIssueDate}
                            </div>
                          ) : null} */}
                    </div>
                  </div>
                  <div className="form-group row mb-3">
                    <label
                      for="idExpiryDate"
                      className="col-form-label col-md-4"
                      style={{
                        fontSize: "13px",
                        fontWeight: "600",
                      }}
                    >
                      ID Expiry Date:
                      <sup className="text-danger bold">*</sup>
                    </label>
                    <div className="col-md-8">
                      <input
                        style={{ fontSize: "11px" }}
                        type="date"
                        id="idExpiryDate"
                        name="idExpiryDate"
                        className="form-control"
                        onChange={handleChange}
                        // {...formik.getFieldProps("idExpiryDate")}
                      />
                      {/* {formik.touched.idExpiryDate &&
                          formik.errors.idExpiryDate ? (
                            <div className="text-danger">
                              {formik.errors.idExpiryDate}
                            </div>
                          ) : null} */}
                    </div>
                  </div>
                  <div className="form-group row mb-3">
                    <label
                      for="idIssuingAuthority"
                      className="col-form-label col-md-4"
                      style={{
                        fontSize: "13px",
                        fontWeight: "600",
                      }}
                    >
                      ID Issuing Authority:
                      <sup className="text-danger bold">*</sup>
                    </label>
                    <div className="col-md-8">
                      <input
                        style={{ fontSize: "11px" }}
                        type="text"
                        id="idIssuingAuthority"
                        name="idIssuingAuthority"
                        className="form-control"
                        placeholder="Enter issuing Authority"
                        onChange={handleChange}
                        // {...formik.getFieldProps("idIssuingAuthority")}
                      />
                      {/* {formik.touched.idIssuingAuthority &&
                          formik.errors.idIssuingAuthority ? (
                            <div className="text-danger">
                              {formik.errors.idIssuingAuthority}
                            </div>
                          ) : null} */}
                    </div>
                  </div>
                  <div className="form-group row mb-3">
                    <label
                      for="idIssuingCountry"
                      className="col-form-label col-md-4"
                      style={{
                        fontSize: "13px",
                        fontWeight: "600",
                      }}
                    >
                      Issuing Country:
                      <sup className="text-danger bold">*</sup>
                    </label>
                    <div className="col-md-8">
                      <Select
                        name="issuingCountry"
                        options={issuingCountry_options}
                        className="basic-multi-select"
                        classNamePrefix="select"
                        onChange={handleChangeIssuingCountry}
                      />
                    </div>
                  </div>
                  <div className="form-group row mb-3 mt-3">
                    <label
                      for="idUpload"
                      className="col-form-label col-md-4"
                      style={{
                        fontSize: "13px",
                        fontWeight: "600",
                      }}
                    >
                      ID Upload:
                    </label>
                    <div className="col-md-8">
                      <input name="idUpload" type="file" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-12">
              <button
                type="submit"
                className="btn btn-primary btn-md mx-1"
                onSubmit={handleSubmit}
                disabled={loading}
              >
                {loading ? (
                  <div className="spinner-border" role="status">
                    <span className="sr-only">Loading...</span>
                  </div>
                ) : (
                  "Save"
                )}
              </button>
            </div>
          </div>
        </form>
      </div>
      {/* <!--//app-card-body--> */}
    </div>
  );
}

export default AddDefaultUser;
