import { createSlice } from "@reduxjs/toolkit";

const countriesSlice = createSlice({
  name: "countries",
  initialState: {
    isLoading: false,
    countries: [],
    country: {},
  },
  reducers: {
    setCountriesIsLoading: (state) => {
      state.isLoading = true;
    },
    resetCountriesIsLoading: (state) => {
      state.isLoading = false;
    },
    setCountries: (state, action) => {
      state.countries = action.payload;
    },
    resetCountries: (state) => {
      state.countries = [];
    },
    setCountry: (state, action) => {
      state.countries = action.payload;
    },
    resetCountry: (state) => {
      state.country = {};
    },
  },
});

export const {
  setCountriesIsLoading,
  resetCountriesIsLoading,
  setCountries,
  resetCountries,
  setCountry,
  resetCountry,
} = countriesSlice.actions;

export default countriesSlice.reducer;
