import React, { useEffect } from "react";
import Login from "./pages/Login";
import MFA from "./pages/MFA";
import Admin from "./layout/Admin";
import {
  Route,
  Switch,
  Redirect,
  Router,
  useHistory,
  useLocation,
} from "react-router-dom";
import NotFound from "./pages/NotFound";
import ProtectedRoute from "./components/ProtectedRoute";
import { logout } from "./redux/Slices/Auth";
import { useDispatch } from "react-redux";
import jwtDecode from "jwt-decode";
import { toast } from "react-toastify";

import { middleware, verifyUser } from "./services/authService";
import { store } from "./redux/store";
import { createBrowserHistory } from "history";
import "./styles.css";
import ResetPassword from "./components/Admin/Auth/ResetPassword";
import ChangePassword from "./components/Admin/Auth/ChangePassword";
// import Policies from "./components/Policies/Policies";

const token = localStorage.getItem("token");
let history = createBrowserHistory();

async function verifyToken() {
  // history.push('/check-authorization')
  middleware(store.dispatch, history);
}

if (token) verifyToken();

export default function App() {
  const history = useHistory();
  const token = localStorage.getItem("token");
  const dispatch = useDispatch();
  const location = useLocation();

  const handleTokenExpiry = () => {
    history.push("/");
  };

  const deleteExpiredToken = () => {
    localStorage.removeItem("token");
  };

  useEffect(() => {
    if (token) {
      // const { token } = JSON.parse(user);
      const decodedToken = jwtDecode(token);
      if (decodedToken.exp * 1000 < new Date().getTime()) {
        dispatch(logout());
        deleteExpiredToken();
      }
    }
  }, [location]);

  return (
    <Router history={history}>
      <Switch>
        <Route exact path="/" component={Login} />
        <Route exact path="/check-authorization" component={MFA} />
        <Route exact path="/reset-password" component={ResetPassword} />
        <Route exact path="/auth/reset_password" component={ChangePassword} />

        <Route path="/admin" render={(props) => <Admin />} />
        <Route path="*">
          <NotFound />
        </Route>
      </Switch>
    </Router>
  );
}
