import React, { useState, useEffect } from "react";
import { Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import {
  CancelPending,
  loginErrorVerify,
  loginRequest,
} from "../redux/Slices/Auth";
import { verify2fa } from "../services/2faServices";
import { middleware, verifySession, verifyUser } from "../services/authService";
import Loading from "../components/Backdrop";
import "./style.css";

function Validate2FA() {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const [code, setCode] = useState(null);
  const [verifying, setVerifying] = useState(true);
  const { auth, session, token } = useSelector((state) => state.auth);

  const handleVerify = async (e) => {
    e.preventDefault();
    const { data } = await verify2fa(code);
    if (data.success) {
      toast.success("Authenticated");
      verifyUser(dispatch);
    } else {
      toast.error(data.message);
      setCode("");
    }
  };

  const authNext = () => {
    if (location.pathname == "/check-authorization") {
      history.push("/admin");
    } else {
      history.push(location.pathname);
    }
  };

  const handleSession = async () => {
    const { data } = await verifySession(session);
    if (data?.result?.session) {
      authNext();
    } else {
      if (auth?.id) {
        authNext();
      } else {
        if (data?.result?.mfa) {
          setVerifying(false);
        } else {
          verifyUser(dispatch);
        }
      }
    }
  };

  useEffect(() => {
    handleSession();
  }, [auth?.id, localStorage.getItem("session")]);

  return verifying ? (
    <Loading />
  ) : (
    <section
      className="h-100 gradient-form"
      style={{ backgroundColor: "#eee" }}
    >
      <div className="container py-3 h-100">
        <div className="row d-flex justify-content-center align-items-center h-100">
          <div className="col-xl-6">
            <div className="card rounded-3 text-black">
              <div className="row g-0">
                <div className="card-body text-center">
                  <Form onSubmit={handleVerify}>
                    <div className="mb-3">
                      <h5>Enter the 6-digit 2FA code</h5>

                      <div className="col-sm-6 mx-auto my-4">
                        <input
                          name="code"
                          value={code}
                          placeholder="Enter Code"
                          type="text"
                          className="form-control form-control-sm text-center"
                          onChange={(e) => setCode(e.target.value.slice(0, 6))}
                          style={{
                            fontSize: "13px",
                          }}
                        />
                      </div>
                      <button
                        disabled={code?.length != 6}
                        type="submit"
                        className="btn btn-primary btn-block"
                      >
                        Verify
                      </button>
                    </div>
                  </Form>
                </div>
              </div>
            </div>
            <div className="mt-3 d-flex justify-content-end align-items-center">
              <h6 className="link" onClick={() => dispatch(loginErrorVerify())}>
                Logout / Cancel
              </h6>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Validate2FA;
