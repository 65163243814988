import { useFormik, validateYupSchema } from "formik";
import React, { useState } from "react";
import { Fade, Slide } from "react-awesome-reveal";
import { Link, useHistory } from "react-router-dom/cjs/react-router-dom.min";
import * as Yup from "yup";
import http from "../../../http-common";
import { toast } from "react-toastify";
import EmailSentCard from "./EmailSentCard";
const ResetPassword = () => {
  const [emailSent, setEmailSent] = useState(false);
  const formik = useFormik({
    initialValues: {
      email: "",
    },
    onSubmit: async (values) => {
      try {
        const { data } = await http.post("/reset_password_request", values);
        console.log(data);
        setEmailSent(true);
      } catch (err) {
        console.log(err?.response);
        toast.error(
          err?.response?.data?.message ||
            "Server Error: Cannot send reset email"
        );
      }
    },
    validationSchema: Yup.object().shape({
      email: Yup.string()
        .email("Please enter a valid email")
        .required("Email is required"),
    }),
  });
  return (
    <div
      className="h-100 d-flex justify-content-center align-items-center"
      style={{
        minHeight: "100vh",
      }}
    >
      {emailSent ? (
        <Slide direction="right">
          <EmailSentCard />
        </Slide>
      ) : (
        <Slide>
          <div className="card" style={{ width: 500 }}>
            <div className="card-body">
              <h1 className="h4 text-center mb-5">Reset Password</h1>
              <form onSubmit={formik.handleSubmit}>
                <p className="text-sm">Please enter your email.</p>
                <input
                  type="email"
                  id="email"
                  onChange={formik.handleChange}
                  value={formik.values.email}
                  className="form-control d-block w-100 "
                  placeholder="Email address"
                />

                {formik.errors.email && (
                  <p className="my-2 text-sm text-danger">
                    {formik.errors.email}
                  </p>
                )}

                <div className="col-12 mt-3">
                  <p>
                    Remembered your password?{" "}
                    <span>
                      <Link to="/">Login</Link>
                    </span>{" "}
                  </p>
                </div>
                <button
                  disabled={formik.isSubmitting}
                  type="submit"
                  className="btn btn-primary btn-sm"
                >
                  {formik.isSubmitting ? "Sending... email" : "Send Reset Link"}
                </button>
              </form>
            </div>
          </div>
        </Slide>
      )}
    </div>
  );
};

export default ResetPassword;
