import { createSlice } from "@reduxjs/toolkit";

const banksSlice = createSlice({
  name: "banks",
  initialState: {
    banks: [],
    bank: {},
    transactions: [],
    transaction: {},
    isLoading: false,
  },

  reducers: {
    setBanksIsLoading: (state) => {
      state.isLoading = true;
    },
    resetBanksIsLoading: (state) => {
      state.isLoading = false;
    },
    setBanks: (state, action) => {
      state.banks = action.payload;
    },
    resetBanks: (state) => {
      state.banks = [];
    },
    setBank: (state, action) => {
      state.bank = action.payload;
    },
    resetBank: (state) => {
      state.bank = {};
    },

    setBankTransactions: (state, action) => {
      state.transactions = action.payload;
    },
    resetBankTransactions: (state) => {
      state.transactions = [];
    },
    setBankTransaction: (state, action) => {
      state.transaction = action.payload;
    },
    resetBankTransaction: (state) => {
      state.transaction = {};
    },
  },
});

export const {
  setBanksIsLoading,
  resetBanksIsLoading,
  setBanks,
  resetBanks,
  setBank,
  resetBank,
  setBankTransaction,
  resetBankTransaction,
  setBankTransactions,
  resetBankTransactions,
} = banksSlice.actions;

export default banksSlice.reducer;
