import React from "react";

const EmailSentCard = () => {
  return (
    <div className="card" style={{ width: 400 }}>
      <div className="card-body">
        <h1 className="h6 text-success text-center mb-5">
          Email Successfully sent
        </h1>
        <p>An email has been successfully sent to your email.</p>
        <p>Please check your inbox to for guidelines</p>
      </div>
    </div>
  );
};

export default EmailSentCard;
