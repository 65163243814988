import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { Slide } from "react-awesome-reveal";
import {
  Link,
  useHistory,
  useLocation,
} from "react-router-dom/cjs/react-router-dom.min";
import * as Yup from "yup";
import http from "../../../http-common";
import PasswordLinkExpired from "./PasswordLinkExpired";
import { toast } from "react-toastify";
const ChangePassword = () => {
  const history = useHistory();
  const formik = useFormik({
    initialValues: {
      newPassword: "",
      confirmNewPassword: "",
    },
    onSubmit: async (values) => {
      try {
        const { data } = await http.post("/reset_password", {
          password: values.newPassword,
          passwordConfirm: values.confirmNewPassword,
          userId,
        });
        toast.success(
          data?.message ||
            "Password successfully changed. Please check your email for confirmation."
        );

        history.push("/");
      } catch (err) {
        console.log(err?.response);
        toast.error(
          err?.response?.data?.message || "Server Error: Cannot change password"
        );
      }
    },
    validationSchema: Yup.object().shape({
      newPassword: Yup.string()
        .required("Required")
        .min(8, "Password is too short - should be 8 chars minimum.")
        .matches(/[a-zA-Z]/, "Password can only contain Latin letters."),
      confirmNewPassword: Yup.string()
        .required("Required")
        .oneOf([Yup.ref("newPassword"), null], "Passwords must match"),
    }),
  });

  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const emailToken = searchParams.get("emailToken");
  const userId = searchParams.get("userId");
  const [validating, setValidating] = useState(false);
  const [tokenIsInValid, setTokenIsInValid] = useState(false);

  const verifyToken = async () => {
    setValidating(true);
    try {
      await http.post("/verify_token", {
        emailToken,
        userId,
      });
    } catch (err) {
      console.log(err?.response);
      setTokenIsInValid(true);
    }
    setValidating(false);
  };

  useEffect(() => {
    verifyToken();
  }, []);
  return (
    <div
      className="h-100 d-flex justify-content-center align-items-center"
      style={{
        minHeight: "100vh",
      }}
    >
      {validating ? (
        <div className="card" style={{ width: 350 }}>
          <div className="card-body">
            <h6 className="h6">Verifying... your token</h6>
          </div>
        </div>
      ) : tokenIsInValid ? (
        <Slide direction="left">
          <PasswordLinkExpired />
        </Slide>
      ) : (
        <Slide direction="right">
          <div className="card" style={{ width: 500 }}>
            <div className="card-body">
              <h1 className="h4 text-center mb-5">Change Password</h1>
              <form onSubmit={formik.handleSubmit} className="row g-3">
                <div className="col-12">
                  <label htmlFor="form-label">New Password</label>
                  <input
                    id="newPassword"
                    value={formik.values.newPassword}
                    onChange={formik.handleChange}
                    className="form-control"
                    type="password"
                    placeholder="New password"
                  />

                  {formik.errors.newPassword && (
                    <p className="text-sm text-danger">
                      {formik.errors.newPassword}
                    </p>
                  )}
                </div>
                <div className="col-12">
                  <label htmlFor="form-label">Confirm Password</label>
                  <input
                    className="form-control"
                    type="password"
                    placeholder="Confirm password"
                    id="confirmNewPassword"
                    value={formik.values.confirmNewPassword}
                    onChange={formik.handleChange}
                  />
                  {formik.errors.confirmNewPassword && (
                    <p className="text-sm text-danger">
                      {formik.errors.confirmNewPassword}
                    </p>
                  )}
                </div>
                <div className="col-12">
                  <p>
                    Remembered your password?{" "}
                    <span>
                      <Link to="/">Login</Link>
                    </span>{" "}
                  </p>
                </div>
                <div className="col-12">
                  <button
                    disabled={formik.isSubmitting}
                    type="submit"
                    className="btn btn-success btn-sm"
                  >
                    {formik.isSubmitting
                      ? "Please wait... changing password"
                      : "Save new password"}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </Slide>
      )}
    </div>
  );
};

export default ChangePassword;
