import React from "react";
import { Link } from "react-router-dom";

export default function NotFound() {
  return (
    <div>
      {/* <!--//app-branding-->   */}
      <div className="app-card p-5 text-center shadow-sm">
        <img
          className="logo-icon me-2"
          src="/assets/images/afroexpress.jpeg"
          alt="logo"
          style={{
            width: 200,
            height: 200,
            objectFit: "scale-down"
          }}
        />

        <h3 className="page-title mb-4">
          <span className="font-weight-light ml-2">404 Page Not Found</span>
        </h3>
        <div className="mb-4">
          Sorry, we can't find the page you're looking for.
        </div>
        <Link className="btn app-btn-primary" to="/">
          Go to home page
        </Link>
      </div>
    </div>
  );
}
