import http from "../http-common";
import { toast } from "react-toastify";
import { updateState } from "../redux/Slices/Auth";

// ENABLE 2FA
export const enable2fa = async (option) => {
  return await http
    .post(`/users/user_2fa_enable`, { option })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};
// Sign
export const sign2fa = async () => {
  return await http
    .get(`/users/user_2fa`)
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};

// Validate 2FA
export const verify2fa = async (token) => {
  return await http
    .post(`/users/user_2fa_verify`, { token })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};
