import { createSlice } from "@reduxjs/toolkit";

export const customerSlice = createSlice({
  name: "customer",
  initialState: {
    customers: {},
    isSearchEmpty: true,
    pending: false,
    error: false,
    errorMsg: "",
  },

  reducers: {
    handleCustomerRequest: (state) => {
      state.pending = true;
    },
    createCustomerSuccess: (state) => {
      state.pending = false;
      state.error = false;
      state.errorMsg = "";
    },
    searchCustomerSuccess: (state, action) => {
      state.pending = false;
      state.customers = action.payload;
      state.isSearchEmpty = false;
      state.error = false;
      state.errorMsg = "";
    },
    searchCustomerNotFound: (state) => {
      state.customers = {};
      state.isSearchEmpty = true;
      state.pending = false;
      state.error = false;
      state.errorMsg = "";
    },
    handleCustomerError: (state, action) => {
      state.error = true;
      state.pending = false;
      state.errorMsg = action.payload;
    },
    handleCustomerClear: (state, action) => {
      state.customers = [];
    },
  },
});

export const {
  handleCustomerRequest,
  createCustomerSuccess,
  searchCustomerSuccess,
  handleCustomerError,
  searchCustomerNotFound,
  handleCustomerClear,
} = customerSlice.actions;
export default customerSlice.reducer;
