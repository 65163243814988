import React from "react";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

const PasswordLinkExpired = () => {
  return (
    <div className="card" style={{ width: 400 }}>
      <div className="card-body">
        <h6 className="h5 text-danger text-center mb-3">Invalid Token</h6>
        <p>Your password reset token is either invalid or expired</p>
        <p>
          Please <Link to="/reset-password">click here</Link> to generate a new
          link
        </p>
      </div>
    </div>
  );
};

export default PasswordLinkExpired;
