import React from 'react'

export default function Footer() {
    return (
        <div>
             <footer className="app-footer">
                <div className="container text-center py-3">
                    <small className="copyright">Designed with <i className="fas fa-heart" styles="color: #fb866a;"></i> by 
                        &nbsp;<a className="app-link" href="https://zigtech.com" target="_blank" rel="noreferrer">Zigtech</a> 
                    </small>
                </div>
            </footer>
            {/* <!--//app-footer--> */}
        </div>
    )
}
